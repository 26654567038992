.people {
    .grid {
        display: flex;
        flex-wrap: wrap;
        // outline: 1px solid;

        // Two columns - default
        @include when_screen_up_to( 770px ) {
            & > * {
                width: calc((100% - 1rem) / 2);
                margin-right: 1rem;
                margin-bottom: 1rem;

                &:nth-child(2n){
                    margin-right: 0;
                }
            }
        }

        // Three columns - narrower screens
        @include when_screen_between( 770px, 1100px ) {
            & > * {
                width: calc((100% - 2rem) / 3);
                margin-right: 1rem;
                margin-bottom: 1rem;

                &:nth-child(3n){
                    margin-right: 0;
                }
            }
        }

        // Four columns - wider screens
        @include when_screen_between( 1100px, 1400px ) {
            & > * {
                width: calc((100% - 3rem) / 4);
                margin-right: 1rem;
                margin-bottom: 1rem;

                &:nth-child(4n){
                    margin-right: 0;
                }
            }
        }

        // Five columns - even wider screens
        @include when_screen_beyond( 1400px ) {
            & > * {
                width: calc((100% - 4rem) / 5);
                margin-right: 1rem;
                margin-bottom: 1rem;

                &:nth-child(5n){
                    margin-right: 0;
                }
            }
        }
    }

    article {
        background-color: white;
    }
}

.Bio > * {
    height: 100%;
}
.Bio_summary {
    display: flex;
    flex-direction: column;
    height: 100%;

    text-align: center;

    img {
        width: 100%;
        height: auto;
        background-color: palette(grey, placeholder);

        flex-shrink: 0; // for ie11, to prevent stretching
    }
    h3 {
        margin-bottom: 0;
    }
    h3,
    .title {
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        @include when_screen_up_to( 500px ) {
            padding-left: 0.75rem;
            padding-right: 0.75rem;
        }
    }
    .title {
        flex-grow: 1;
        font-size: 0.8rem;
    }
    .bio {
        display: none;  // Should only be visible when class is Bio_detail (js = enabled)
    }
    a {
        align-self: center;

        display: inline-block;
        margin-top: 1rem;
        margin-bottom: 1.75rem;

        padding: 0.5em 2em;
        @include when_screen_up_to( 500px ) {
            padding-left: 1.25em;
            padding-right: 1.25em;
        }

        cursor: pointer;
        
        text-decoration: none;

        background-color: palette(yellow, text);
        color: white;
        border: 1px solid;
        &:hover {
            background-color: white;
            color: palette(yellow, text);
        }
    }

}

.Bio_detail {
    a {
        color: inherit;
    }
    h3 {
        margin-bottom: 0.5em;
    }
    .title {
        margin-top: 0;
        margin-bottom: 1.5em;
        font-weight: bold;
    }
}


// On narrow screens, tighten up horizontal padding
@include when_screen_up_to( 400px ) {
    .people .wrapper {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .people .grid {
        & > * {
            width: calc((100% - 0.5rem) / 2);
            margin-right: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
    .Bio_summary {
        h3,
        .title {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
}


