body.page_404 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    header {
        position: static;
    }
    main section {
        text-align: center;
    }
}