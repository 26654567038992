$gutter: 15px;


// Default styles for everyone

.portfolio .grid {
    & > * {
        max-width: 35em;
        margin-bottom: $gutter;
        // outline: solid 1px green;
    }
    & > article {
        display: flex;
    }
    & > picture {

        // Hide photos by default
        display: none;

        // Cover image over the cell
        position: relative;
        &:after {
            content: '';
            display: inline-block;
            position: relative;
            width: 1px;
            height: 0;
            padding-top: 100%;      // photo should be at least as tall as it is wide
        }
        img {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
        }
    }
}

// On narrow screens, remove side padding
@include when_screen_up_to( 400px ) {
    .portfolio .wrapper {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}



// Flex-box layout for browsers who don't know grid (ie11)
// (When IE11 is no longer a thing, delete the block of code below.)
.portfolio .grid {

    // Default - Single column (flex)
    & > * {
        margin-left: auto;
        margin-right: auto;
    }
    
    // Use flexbox above a certain width
    @include when_screen_beyond( 800px ) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & > * {
            max-width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
        & > picture {
            // Show all photos...
            display: block;

            // ... except second one.
            & + picture {
                display: none;
            }
        }
    }

    // Dual columns (flex)
    @include when_screen_between( 800px, 1200px ) {
        & > * {
            width: calc( (100% - #{$gutter}) / 2);
        }
    }

    // Three columns (flex)
    @include when_screen_beyond( 1200px ) {
        & > * {
            width: calc( (100% - (2 * #{$gutter})) / 3);
        }
    }


    // Undo flex-box styles for those browsers who can do grid
    @supports (display: grid) {
        & > * {
            width: auto;
        }
    }    
}



// For browsers that know grid

@supports (display: grid) {

    .portfolio .grid {

        // Default - Single column

        & > * {
            // Fix for ios safari: https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items
            min-width: 0;       
            overflow: hidden;
        }

    
        // Use grid above a certain width

        @include when_screen_beyond( 800px ) {
            display: grid;
            grid-column-gap: $gutter;
            align-items: stretch;
        }


        // Dual columns - narrower screens (grid)

        @include when_screen_between( 800px, 1200px ) {
            grid-template-columns: 1fr 1fr;

            & > picture {
                // Show all photos...
                display: block;

                // ... except second one.
                & + picture {
                    display: none;
                }
            }
        }


        // Three columns - wider screens (grid)

        @include when_screen_beyond( 1200px ) {
            grid-template-columns: 1fr 1fr 1fr;

            & > picture {
                // Show all photos
                display: block;

                // First photo stretches across two rows
                &:nth-child(1) {
                    grid-row-start: 1;
                    grid-row-end: 3;
                }
                // Second photo stretches across two columns
                & + picture {
                    display: block; // overrides flexbox code; not necessary if removing flexbox alternate
                    grid-column-start: 2;
                    grid-column-end: 4;
                    &:after {
                        padding-top: 50%;      // photo should be half as tall as it is wide
                    }
                }
            }
        }   
    }
}
