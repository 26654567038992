
html {
    color: palette(blue, text);
    font-family: 'Lato', sans-serif;
    line-height: 1.4;

    background-color: palette(grey, background);
}
a {
    color: palette(blue, text);
    transition: all 0.25s;
}

.wrapper {
    max-width: 1720px;
    margin: 0 auto;
    padding: 0 2rem;
    @include when_screen_up_to( 500px ) {
        padding: 0 1rem;
    }
}
img {
    max-width: 100%;
}
h2 {
    margin-top: 2em;
    margin-bottom: 2em;
    
    // Line behind
    span {
        position: relative;
        display: inline-block;
        padding: 0 0.75rem;
        margin: 0 0.75rem;
        background-color: palette(grey, background);
    }
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        height: 2px;
        left: 0;
        right: 0;

        background-color: palette(yellow);
    }

    font-family: 'Satisfy', 'Brush Script MT', Georgia, 'Trebuchet MS', serif;
    font-size: 2rem;
    font-weight: normal;
    white-space: nowrap;

    @include when_screen_up_to( 800px ) {
        text-align: center;
        font-size: 2.5rem;
    }
}

