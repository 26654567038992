.scrim_is_visible {
    body {
        height: 100%;
        overflow: hidden;
        position: fixed;
    }
}

.Scrim {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: z('scrim');

    pointer-events: none;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: opacity 0.5s;
        
        cursor: pointer;
    }
    .scrim_is_visible & {
        pointer-events: auto;

        &:before {
            opacity: 1;
        }
    }

}

.Drawer {
    background-color: palette(blue);
    color: palette(grey, text);

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;

    z-index: z('drawer');

    width: 40em;
    max-width: 100%;

    // Animate drawer
    transform: translateX(0);
    transition: transform 0.5s;
    &[hidden] {
        display: block;
        transform: translateX(100%);
    }
}

.Drawer_scrollable {
    height: 100%;
    overflow-y: auto;
    padding: 2rem 6rem 4rem 2rem;
    -webkit-overflow-scrolling: touch;
}

.Drawer_close {
    // Shape
    display: block;
    width: 2rem;
    height: 2rem;
    background-image: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAlCAQAAABvl+iIAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfiBAMPMBywdjSGAAAAvUlEQVRIx72WQQrDMAwEBQb/H3L1Ne/KRwzTU0pKE3vlLiWn2JuxIsmSgo1OoxDLT6HR2YIOwL4MK+wA9KDBD7ATBO36kod9fPu1sAoibhdXQCcqD7vRDzczoCtKhz3oJJGmkYXz/ZR4fFDmFyYW6/6Y+lGNkhAQLeRVSRMtEQ8l39SsFm7BGBXUt0UHdaz9k1U2X9kiaMsrW7bb7qCtMtjqla2K2mq7rePY+qCtO9tmBuMkY5yvjFOfbRZ9Af0pfZm8p5R/AAAAAElFTkSuQmCC");
    background-size: contain;

    // Position
    position: fixed;
    top: 2rem;
    right: 2rem;

    cursor: pointer;

    // Animate close icon (IE11 only)
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        transform: translateX(0);
        transition: transform 0.5s;
        .Drawer[hidden] & {
            display: block;
            transform: translateX(300%);
        }
    }
}

.Drawer_content {
    max-width: 25em;

    img {
        width: 100%;
        height: auto;
    }
}
.has_no_photo .Drawer_content img {
    display: none;
}
