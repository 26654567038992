.portfolio article {
    background-color: white;
    padding-top: 1.5em;
    padding-bottom: 2.5em;
    padding-left: 3em;
    padding-right: 3em;
    @include when_screen_up_to( 900px ) {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    text-align: center;
    img {
        margin-bottom: 1em;
        height: auto;
    }
    p {
        margin-bottom: 2em;
        text-align: left;
    }

    // Align link to bottom
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    p {
        flex-grow: 1;
        max-width: 100%;    // fixes ie11 issue
    }
}

.company_link {
    display: inline-block;
    padding: 0.75em 2em;
    border: 1px solid palette(yellow, text);

    color: palette(yellow, text);
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
        background-color: palette(yellow, text);
        color: white;
    }

    @include when_screen_up_to( 500px ) {
        font-size: 88%;
    }
}
