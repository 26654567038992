footer {
    padding: 3rem 0;

    background-color: palette(blue);
    border-top: 3px solid palette(yellow);

    &, a {
        color: palette(grey, text);
    }

    font-size: 0.7rem;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    
    // Align sections
    .wrapper {
        span {
            white-space: nowrap;
        }
        @include when_screen_up_to( 800px ) {
            & > * {
                display: block;
            }
            & > *:first-child {
                margin-bottom: 1.5em;
            }
        }
        @include when_screen_beyond( 800px ) {
            display: flex;
            justify-content: space-around;
        }
    }
}
