header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 20px;
    padding-bottom: 10px;

    background-image: linear-gradient(
                    rgba(255,255,255, 0.9), 
                    rgba(255,255,255, 0.75), 
                    rgba(255,255,255, 0.65), 
                    rgba(255,255,255, 0.45), 
                    rgba(255,255,255, 0)
                    );
}
// Everything in a row
header .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    & > * {
        // outline: pink solid;
    }
}

nav {
    flex: 1;

    display: flex;
    justify-content: flex-end;

    a {
        display: block;
        
        text-transform: uppercase;
        text-decoration: none;
    }
}

// Logo size
header img {
    max-width: 130px;
}

// Match spacing of nav items and phone
nav a,
header .phone {
    margin-bottom: 1em;
    margin-left: 0.25em;
    margin-right: 0.25em;
    padding: 0.5em 2.5%;
    white-space: nowrap;
}

header .phone {
    border: solid 1px;
    margin-left: 5%;
}


// Small screen overrides

@include when_screen_up_to( 600px ) {
    // Center logo
    header .wrapper {
        justify-content: center;
    }
    
    // Shrink logo
    header img {
        // max-width: 100px;
    }
    
    // Hide nav
    nav {
        display: none;
    }   

    // Hide phone
    header .phone {
        display: none;
    }
}
@include when_screen_between( 600px, 900px ) {
    // Shrink logo
    header img {
        max-width: 100px;
    }
    
    // Shrink nav
    nav {
        font-size: 0.85rem;
    }   

    // Adjust nav spacing
    nav a {
        margin-bottom: 2em;
    }

    // Hide phone
    header .phone {
        display: none;
    }
}