@mixin when_screen_shorter_than( $max ) {
    @media only screen and (max-height: $max ) { @content; }
}

@mixin when_screen_up_to( $max ) {
    @media only screen and (max-width: $max) { @content; }
}
@mixin when_screen_beyond( $min ) {
    @media only screen and (min-width: ($min + 1)) { @content; }
}
@mixin when_screen_between( $min, $max ) {
    @media only screen and (min-width: ($min + 1)) and (max-width: $max) { @content; }
}
@mixin when_screen_shorter_than( $max ) {
    @media only screen and (max-height: $max) { @content; }
}
@mixin when_screen_taller_than( $min ) {
    @media only screen and (min-height: $min + 1) { @content; }
}

