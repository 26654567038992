.intro {
    height: 100vh;
    min-height: 650px;

    @include when_screen_up_to( 600px ) {
        background-image: url("../images/header-photo-med.jpg");
        background-position: center top;
    }
    @include when_screen_beyond( 600px ) {
        background-image: url("../images/header-photo.jpg"), url("../images/header-photo-12.jpg");
        background-position: right top, right top;
    }
    background-size: cover, cover;

    text-align: center;
}

.intro .wrapper {
    // Vertically align content to the bottom of the page
    height: 100vh;
    min-height: 650px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    & > * {
        margin-bottom: 3rem;
        // outline: solid red;
    }

    // Scrim for intro text
    max-width: 100%;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0), 
        rgba(0, 0, 0, 0.025),
        rgba(0, 0, 0, 0.075),
        rgba(0, 0, 0, 0.125),
        rgba(0, 0, 0, 0.15)
        )
}

// Type
.intro p {
    max-width: 22em;

    font-size: 2rem;
    font-weight: 300;
    color: white;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}



// Tighten up for small screens
@include when_screen_up_to( 700px ) {
    .intro {
        &,
        .wrapper {
            height: auto;
            min-height: 100vh;
        }
        .wrapper {
        }
        p {
            font-size: calc(16px + 1.75vw);
            padding-left: 1rem;
            padding-right: 1rem;
            margin-bottom: 0;
        }
        img {
            max-width: 60%;
        }
    }
}
@include when_screen_shorter_than( 600px ) {
    .intro img {
        display: none;
    }
}


.scroll_hint {
    display: block;
    
    svg {
        width: 35px;
    }

    animation: pogo 0.75s ease-in infinite alternate;
}

@keyframes pogo {
    0% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(10px);
    }
}

