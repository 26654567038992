.contact {
    &:after {
        content: "";
        display: block;
        min-height: 35vw; // this enforces the ratio of the bg photo

        @include when_screen_up_to(800px) {
            background-image: url("../images/footer-photo-small.png");
        }
        @include when_screen_beyond(800px) {
            background-image: url("../images/footer-photo.png");
        }
        background-position: center bottom;
        background-size: 100% auto;
    }
}

.contact > .wrapper {
    max-width: 28em;
    @include when_screen_beyond(800px) {
        margin-bottom: -2rem;
    }

    h4 {
        margin-top: 0;
        margin-bottom: 0.5em;
        font-size: inherit;
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 1.3;
    }

    a {
        color: palette(yellow);
        text-decoration: none;
        font-weight: bold;
    }
}

/* Social sharing icons */
.contact .sharing {
    // outline: dashed 1px;
    font-size: 2.5rem;
    margin-top: 1em;
}
.contact .sharing ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 0.85em;
}
.contact .sharing svg {
    display: block;
    // fill: none;
}
.contact .sharing a {
    color: palette(blue, text);
}
